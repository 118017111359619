import { SearchOutlined } from '@ant-design/icons';
import { Input, Layout, Table } from 'antd';
import Column from 'antd/lib/table/Column';
import { debounce } from 'lodash';
import moment from 'moment';
import type { RangeValue } from 'rc-picker/lib/interface';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { RangePicker } from 'app/components/fields/DatePicker';
import ListPage from 'app/components/lists/ListPage';
import Pagination from 'app/components/lists/Pagination';
import {
  StatusCell,
  dateTimeCell,
  dateTimeCell2,
} from 'app/components/lists/cells';
import { DEBOUNCE_TIME_MS, PAGE_LIMIT } from 'app/components/lists/constants';
import ModalCheckBox from 'app/components/pages/Extranet/ExtranetBookings/ModalCheckBox';
import {
  TryFetchBookingsPayload,
  tryFetchBookings,
} from 'app/redux/actions/bookings';
import { useAppDispatch } from 'app/redux/hooks';
import {
  Booking,
  BookingStatus,
  PSP,
  isPSP,
  isValidStatus,
} from 'app/redux/models/Booking/Booking';
import {
  areBookingsLoading,
  getBookingsList,
} from 'app/redux/selectors/bookings';
import {
  parseArrayParam,
  useQueryParamHistory,
} from 'app/utils/queryParamHistory';
import { scrollToTop } from 'app/utils/scroll';
import { SortOrder, formatSortingForAPI, getSortOrder } from 'app/utils/sort';
import { toSnakeCase } from 'app/utils/strings';

import 'styles/lists/left-right-header.scss';
import 'styles/pages/Booking/booking.scss';

type BookingsListQueryParam = {
  search: string;
  startDate?: moment.Moment;
  endDate?: moment.Moment;
  pageNumber: number;
  sortOrder?: SortOrder;
  sortingField?: string;
  psp: PSP[];
  status: BookingStatus[];
};

const statusCheckBoxItems = [
  { value: BookingStatus.Accepted, label: 'Accepted' },
  { value: BookingStatus.Cancelled, label: 'Cancelled' },
  { value: BookingStatus.Pending, label: 'Pending' },
  { value: BookingStatus.Expired, label: 'Expired' },
  { value: BookingStatus.Aborted, label: 'Aborted' },
  { value: BookingStatus.Rejected, label: 'Rejected' },
] satisfies { value: BookingStatus; label: string }[];

const pspCheckBoxItems = [
  {
    value: PSP.Ayden,
    label: 'Adyen',
  },
  {
    value: PSP.Stripe,
    label: 'Stripe',
  },
] satisfies { value: PSP; label: string }[];

function getPsp(maybePSP: string): PSP | undefined {
  return isPSP(maybePSP) ? maybePSP : undefined;
}

function getStatus(maybeStatus: string): BookingStatus | undefined {
  return isValidStatus(maybeStatus) ? maybeStatus : undefined;
}

const queryParamInit = {
  pageNumber: 1,
  psp: [PSP.Ayden, PSP.Stripe],
  status: [
    BookingStatus.Cancelled,
    BookingStatus.Accepted,
    BookingStatus.Rejected,
  ],
  sortingField: undefined,
  sortOrder: undefined,
  startDate: moment().subtract(1, 'month'),
  endDate: moment(),
} as BookingsListQueryParam;

const queryParamKeys = {
  pageNumber: 'p',
  psp: 'ps',
  status: 'st',
  createdAtSorting: 'cs',
  firstNameSorting: 'fs',
  lastNameSorting: 'ls',
  search: 's',
  startDate: 'sd',
  endDate: 'ed',
};

const queryParamFormatter = {
  startDate: (val?: moment.Moment) => val?.format('YYYY-MM-DD'),
  endDate: (val?: moment.Moment) => val?.format('YYYY-MM-DD'),
  psp: (val: PSP[]) => (val.length === 1 ? val[0] : undefined),
};

const queryParamExtractor = {
  startDate: moment,
  endDate: moment,
  pageNumber: parseInt,
  createdAtSorting: getSortOrder,
  firstNameSorting: getSortOrder,
  lastNameSorting: getSortOrder,
  psp: parseArrayParam(getPsp),
  status: parseArrayParam(getStatus),
};

const convertPsp = (pspToConvert: string) =>
  pspToConvert.charAt(0) + pspToConvert.slice(1).toLowerCase();

const BookingsList = () => {
  const history = useHistory();
  const bookings = useSelector(getBookingsList);
  const isLoading = useSelector(areBookingsLoading);

  const { queryParam, updateQueryParam } =
    useQueryParamHistory<BookingsListQueryParam>(
      queryParamInit,
      queryParamKeys,
      queryParamFormatter,
      queryParamExtractor
    );
  const pageNumber = queryParam.pageNumber || 1;

  const formatSorting = useCallback(() => {
    if (!queryParam.sortOrder) return undefined;

    return `${toSnakeCase(queryParam.sortingField)}:${formatSortingForAPI(
      queryParam.sortOrder
    )}`;
  }, [queryParam.sortOrder, queryParam.sortingField]);

  const makeParams = useCallback(
    () => ({
      sorting: formatSorting() || undefined,
      offset: (pageNumber - 1) * PAGE_LIMIT,
      search: queryParam.search,
      beginning: queryParam.startDate?.format('YYYY-MM-DD'),
      ending: queryParam.endDate?.format('YYYY-MM-DD'),
      psp: queryParam.psp || [],
      status: queryParam.status || [],
    }),
    [
      formatSorting,
      pageNumber,
      queryParam.endDate,
      queryParam.psp,
      queryParam.search,
      queryParam.startDate,
      queryParam.status,
    ]
  );

  const dispatch = useAppDispatch();

  const debounceFetch = useMemo(
    () =>
      debounce(
        (params: TryFetchBookingsPayload) => dispatch(tryFetchBookings(params)),
        DEBOUNCE_TIME_MS
      ),
    [dispatch]
  );

  useEffect(() => {
    debounceFetch(makeParams());
  }, [debounceFetch, makeParams, queryParam]);

  const handleOffset = (x: number) => {
    updateQueryParam({ pageNumber: pageNumber + x });

    scrollToTop();
  };

  const handleSearch = (nextSearch: string) => {
    updateQueryParam({ search: nextSearch, pageNumber: 1 });
  };

  const handleStatus = (nextStatus: BookingStatus[]) => {
    updateQueryParam({
      status: nextStatus,
      pageNumber: 1,
    });
  };

  const handlePSP = (nextPSP: PSP[]) => {
    updateQueryParam({
      psp: nextPSP,
      pageNumber: 1,
    });
  };

  const handleFilterDate = (interval: RangeValue<moment.Moment> | null) => {
    if (interval === null || interval[0] === null || interval[1] === null) {
      updateQueryParam({
        startDate: undefined,
        endDate: undefined,
        pageNumber: 1,
      });
    } else {
      updateQueryParam({
        startDate: interval[0],
        endDate: interval[1],
        pageNumber: 1,
      });
    }
  };

  const handleRowActions = ({ code }: Booking) => ({
    onClick: () => history.push(`bookings/${code}`),
  });

  const header = () => (
    <div className="left-right-header">
      <div className="left-right-header__left">
        <Input
          placeholder="Search here..."
          onChange={(event) => handleSearch(event.target.value)}
          suffix={<SearchOutlined className="certain-category-icon" />}
          className="left-right-header__search"
          size="large"
          value={queryParam.search}
        />
        <RangePicker
          size="large"
          value={[queryParam.startDate || null, queryParam.endDate || null]}
          onChange={handleFilterDate}
          format="ddd DD MMM"
          ranges={{
            Today: [moment(), moment()],
            'This Week': [
              moment().startOf('isoWeek'),
              moment().endOf('isoWeek'),
            ],
            'Last month': [moment().subtract(1, 'month'), moment()],
            'Last 3 months': [moment().subtract(3, 'months'), moment()],
            'Last year': [moment().subtract(1, 'year'), moment()],
          }}
        />
      </div>
      <Pagination
        className="left-right-header__right"
        pageNumber={pageNumber}
        pageResultsLength={bookings.length}
        handleOffset={handleOffset}
      />
    </div>
  );

  return (
    <ListPage title="Bookings">
      <Layout className="booking">
        <Table
          title={header}
          loading={isLoading}
          onRow={handleRowActions}
          dataSource={bookings}
          pagination={false}
          rowKey="code"
          onChange={(__, ___, sorter) => {
            // @ts-ignore
            const { field, order } = sorter;

            updateQueryParam({
              sortOrder: order,
              sortingField: typeof field !== 'string' ? field[1] : field,
            });
          }}
        >
          <Column
            key="code"
            title="Code"
            dataIndex="code"
            render={(elem) => <div style={{ fontWeight: 500 }}>{elem}</div>}
          />

          <Column
            title="Created at"
            sorter
            dataIndex="createdAt"
            render={dateTimeCell2}
          />

          <Column
            key="firstName"
            title="First name"
            sorter
            dataIndex={['customer', 'firstName']}
          />

          <Column
            key="lastName"
            title="Last name"
            sorter
            dataIndex={['customer', 'lastName']}
          />

          <Column
            key="checkin"
            title="Check in"
            render={dateTimeCell('dd/MM/yyyy')}
            dataIndex="checkin"
          />

          <Column key="hotelName" title="Hotel" dataIndex="hotelName" />

          <Column
            key="status"
            className="sort-column"
            title={
              <ModalCheckBox
                title="Status"
                value={queryParam.status}
                onChange={handleStatus}
                items={statusCheckBoxItems}
              />
            }
            dataIndex="status"
            render={StatusCell}
          />

          <Column
            key="payment"
            className="sort-column"
            title={
              <ModalCheckBox
                title="Payment"
                onChange={handlePSP}
                items={pspCheckBoxItems}
                value={queryParam.psp}
              />
            }
            dataIndex="psp"
            render={convertPsp}
          />
        </Table>

        <Pagination
          className="booking__footer"
          pageNumber={pageNumber}
          pageResultsLength={bookings.length}
          handleOffset={handleOffset}
        />
      </Layout>
    </ListPage>
  );
};

export default BookingsList;
