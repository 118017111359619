import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Collapse, Form, Modal, Select } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { BillingContact } from 'app/components/commons/BillingContact/BillingContact';
import { BookingContact } from 'app/components/commons/BookingContact/BookingContact';
import { GuestContact } from 'app/components/commons/GuestContact/GuestContact';
import { SaleContact } from 'app/components/commons/SaleContact/SaleContact';
import SearchBox from 'app/components/fields/SearchBox';
import FormSection from 'app/components/forms/FormSection';
import { Hotel } from 'app/redux/models/Hotel/Hotel';
import { AutoInventoryStatus } from 'app/utils/autoInventory/autoInventory';
import {
  ChannelManagerDataSource,
  ChannelManagers,
} from 'app/utils/channelManagers/channelManagers';
import { RateModesDataSource } from 'app/utils/channelManagers/rateModes';

import './Communication.scss';
import style from './Communication.style';

type Props = {
  mode: 'view' | 'edit';
  values: Hotel;
  setFieldsValue: (value: Store) => void;
  originalBillingEmails: string[] | undefined;
};

const RULES = [{ required: true }];

const Communication = ({
  mode,
  values,
  setFieldsValue,
  originalBillingEmails,
}: Props) => {
  const communicationRef = useRef<null | HTMLDivElement>(null);
  const { hash } = useLocation();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const hasChannelManager =
    values.channelManager && values.channelManager !== ChannelManagers.NONE;

  useLayoutEffect(() => {
    if (
      communicationRef &&
      communicationRef.current &&
      hash === '#communication'
    ) {
      communicationRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }, [communicationRef, hash]);

  const isDisabled = mode === 'view';

  return (
    <div ref={communicationRef} className="communicationContainer">
      <FormSection title="Communication">
        <Collapse
          bordered={false}
          defaultActiveKey={[
            'sale-contact',
            'notif-contact',
            'guest-contact',
            'billing-contact',
          ]}
        >
          <SaleContact mode={mode} key="sale-contact" />

          <BookingContact
            key="notif-contact"
            mode={mode}
            notifEmails={values.notifEmails}
            billingEmails={values.billingEmails}
            setNotifEmails={(notifEmails) => {
              setFieldsValue({ ...values, notifEmails });
            }}
            channelManager={
              <>
                <Form.Item
                  label="Channel manager"
                  name="channelManager"
                  rules={RULES}
                >
                  <SearchBox
                    dataSource={ChannelManagerDataSource}
                    mode={mode}
                    style={style.searchBoxField}
                    disabled={isDisabled}
                    onChange={(value: ChannelManagers) =>
                      values.stockScrapperEnabled &&
                      value !== ChannelManagers.NONE &&
                      setOpenConfirmModal(true)
                    }
                  />
                </Form.Item>
                {hasChannelManager && (
                  <Form.Item
                    label="Connected rates"
                    name="rateMode"
                    rules={RULES}
                  >
                    <SearchBox
                      dataSource={RateModesDataSource}
                      mode={mode}
                      style={style.searchBoxField}
                      disabled={isDisabled}
                    />
                  </Form.Item>
                )}
                {!hasChannelManager && (
                  <Form.Item label="Auto inventory" name="stockScrapperEnabled">
                    <Select
                      style={style.searchBoxField}
                      disabled
                      showArrow={false}
                    >
                      <Select.Option value={true}>
                        {AutoInventoryStatus.Activated}
                      </Select.Option>
                      <Select.Option value={false}>
                        {AutoInventoryStatus.Deactivated}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                )}
                <Modal
                  key={'modal'}
                  title={
                    <>
                      <ExclamationCircleOutlined style={{ color: '#FF382C' }} />
                      <span style={{ marginLeft: '6px' }}>Warning</span>
                    </>
                  }
                  open={openConfirmModal}
                  onOk={() => {
                    setOpenConfirmModal(false);
                    setFieldsValue({
                      ...values,
                      stockScrapperEnabled: false,
                    });
                  }}
                  onCancel={() => {
                    setFieldsValue({
                      ...values,
                      channelManager: ChannelManagers.NONE,
                    });
                    setOpenConfirmModal(false);
                  }}
                  okText="OK"
                  cancelText="Cancel"
                >
                  <p>This hotel has activated Auto inventory.</p>
                  <p>
                    Connecting to a Channel Manager will disable the feature and
                    erase all connected data.
                  </p>
                </Modal>
              </>
            }
          />
          <GuestContact mode={mode} key="guest-contact" />
          <BillingContact
            mode={mode}
            key="billing-contact"
            originalBillingEmails={originalBillingEmails}
            notifEmails={values.notifEmails}
          />
        </Collapse>
      </FormSection>
    </div>
  );
};

export default Communication;
