import { Form } from 'antd';

import { Option, Select } from 'app/components/fields/Select';
import { HotelItem } from 'app/redux/models/HotelItem/HotelItem';

const RULES = [{ required: true, message: 'Required' }];

type HotelSelectProps = {
  hotels: Array<HotelItem>;
  hotelId?: number;
  mode: string;
  disabled: boolean;
};

const HotelSelect = ({ hotels, hotelId, mode, disabled }: HotelSelectProps) => (
  <div className="input-row input-row-hotel-select">
    <Form.Item name="hotelId" label="Hotel" rules={RULES}>
      {mode === 'edit' ? (
        <Select
          placeholder="Select a hotel"
          disabled={disabled}
          showSearch
          optionFilterProp="children"
        >
          {hotels.map((hotel) => (
            <Option key={hotel.id} value={hotel.id}>
              {hotel.name}
            </Option>
          ))}
        </Select>
      ) : (
        <div className="view-text">
          {hotels.find((hotel) => hotel.id === hotelId)?.name}
        </div>
      )}
    </Form.Item>
  </div>
);

export default HotelSelect;
