import { Divider, Form } from 'antd';
import { Fragment, useEffect } from 'react';

import { Rules } from 'app/components/pages/Extranet/ExtranetInventory/RoomRow/AutomatedDiscount/Rules';
import {
  RULES,
  RuleKeys,
} from 'app/components/pages/Extranet/ExtranetInventory/RoomRow/AutomatedDiscount/Utils';
import { Inventory } from 'app/components/pages/Extranet/ExtranetInventory/Types';
import { isValidDiscount } from 'app/components/pages/Extranet/ExtranetInventory/Utils';
import { assertNever } from 'app/utils/typing';

import { FormValues } from '../../../Utils';

import './Discount.scss';

type Props = {
  values: FormValues;
  inventory: Inventory;
  setDiscountStepHasError: (val: boolean) => void;
};

export const Discount = ({
  values,
  inventory,
  setDiscountStepHasError,
}: Props) => {
  const form = Form.useFormInstance();

  const errorDiscount =
    !!values.discount &&
    !isValidDiscount(-(values.discount ?? 0), inventory.pkg.dayPackage);

  const errorLMDiscount =
    !!values.lastMinuteDiscountValue &&
    !isValidDiscount(
      -(values.lastMinuteDiscountValue ?? 0),
      inventory.pkg.dayPackage
    );

  const errorLMDays = values.lastMinuteIsActivated && !values.lastMinuteDays;

  useEffect(() => {
    setDiscountStepHasError(errorDiscount || errorLMDays || errorLMDiscount);
  }, [errorDiscount, errorLMDays, errorLMDiscount, setDiscountStepHasError]);

  const toggleSwitch = (switchValue: boolean, section: string) => {
    if (!switchValue) {
      if (section === 'lastMinuteDiscountValue') {
        form.setFieldValue(['lastMinuteDays'], undefined);
      }

      form.setFieldValue([section], undefined);
    }
  };

  const getIsRuleInvalid = (rule: RuleKeys) => {
    switch (rule) {
      case 'standardDiscount':
        return errorDiscount;

      case 'lastMinuteDiscount':
        return errorLMDiscount;

      default:
        assertNever(rule);
    }
  };

  return (
    <div className="discount-step">
      <div className="explanation">
        Apply at least one of the two pricing rules below, which will be the
        discount visible on Staycation.
      </div>
      <div className="pricing-rule">
        {[...RULES].map((rule, index) => {
          return (
            <Fragment key={'rule' + index}>
              <Rules
                ruleInfo={rule}
                isRuleActive={
                  rule.key === 'standardDiscount'
                    ? values.discountIsActivated
                    : values.lastMinuteIsActivated
                }
                inventory={inventory}
                toggleSwitch={(switchValue: boolean) =>
                  toggleSwitch(
                    switchValue,
                    rule.key === 'standardDiscount'
                      ? 'discount'
                      : 'lastMinuteDiscountValue'
                  )
                }
                isSingleRate={true}
                isRuleInvalid={getIsRuleInvalid(rule.key)}
              />
              {index < RULES.length - 1 && (
                <Divider className="divider" plain>
                  <div className="divider-text">and / or</div>
                </Divider>
              )}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};
