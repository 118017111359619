import { useQuery } from '@tanstack/react-query';

import { HotelItem } from 'app/redux/models/HotelItem/HotelItem';
import { IHotel } from 'app/typings';
import { apiRequest } from 'app/utils/request/api';

const path = '/hotels' as const;
const hotelPath = '/hotels/:hotelId' as const;

export function useAdminHotels() {
  return useQuery({
    queryKey: [path],
    queryFn: () => apiRequest({ path }) as Promise<HotelItem[]>,
    staleTime: Infinity,
  });
}

export function useAdminHotel(hotelId: number, enabled: boolean) {
  return useQuery({
    queryKey: [hotelPath, { hotelId }],
    queryFn: () =>
      apiRequest({
        path: hotelPath,
        params: { hotelId: hotelId.toString() },
      }) as Promise<IHotel>,
    staleTime: Infinity,
    enabled,
  });
}
