import { InfoCircleOutlined } from '@ant-design/icons';
import { Alert, Collapse, Table, Tooltip } from 'antd';
import { format } from 'date-fns';
import React, { useContext } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import { Inventory } from 'app/components/pages/Extranet/ExtranetInventory/Types';
import { ModifierType, RoomsWithInventory } from 'app/typings/autoInventory';

import {
  FormValues,
  associateRooms,
  organizePreviewDataByDate,
} from '../../../Utils';

import './Preview.scss';

const { Panel } = Collapse;

type PreviewProps = {
  inventory: Inventory;
  scrappingInventory: RoomsWithInventory[];
  formData: FormValues;
};

export const Preview = ({
  inventory,
  scrappingInventory,
  formData,
}: PreviewProps) => {
  const { currencyFormatter } = useContext(CurrencyContext);

  const filteredRooms = associateRooms(formData, scrappingInventory);

  const formatDataSource = (organizedData: {
    dates: string[];
    stocks: number[];
    bars: number[];
    staycationRates: number[];
    discounts: number[];
    packageValue: number[];
  }) => {
    return [
      {
        key: 'Stock',
        timePeriod: (
          <div>
            Stock
            <Tooltip
              overlayClassName="auto-inventory-tooltip-stock"
              title={
                <div className="tooltip-container">
                  <div className="tooltip-title">
                    We won't sell your last available room to minimise risks of
                    overbooking.
                  </div>
                  <div className="tooltip-subtitle">
                    Staycation stock = Booking.com stock - 1
                  </div>
                </div>
              }
            >
              <span className="grey-icon margin-left">
                <InfoCircleOutlined />
              </span>
            </Tooltip>
          </div>
        ),
        ...organizedData.dates.reduce((acc, _, index) => {
          acc[`date${index}`] = organizedData.stocks[index];

          return acc;
        }, {} as Record<string, string | number | React.ReactNode>),
      },
      {
        key: 'Bar',
        timePeriod: (
          <div>
            BAR
            {formData.customBar.value ? (
              <Tooltip
                overlayClassName="auto-inventory-tooltip-stock"
                title={
                  <div className="tooltip-container">
                    <div className="tooltip-title">
                      Based on your pricing rule :
                    </div>
                    <div className="tooltip-subtitle">
                      {formData.customBar.suffix === ModifierType.RATE
                        ? `BAR = Booking.com Price - (${
                            Math.floor(formData.customBar.value) ?? 0
                          }% * BAR)`
                        : `BAR = Booking.com Price - ${
                            Math.floor(formData.customBar.value) ?? 0
                          }€`}
                    </div>
                  </div>
                }
              >
                <span className="grey-icon margin-left">
                  <InfoCircleOutlined />
                </span>
              </Tooltip>
            ) : null}
          </div>
        ),
        ...organizedData.dates.reduce((acc, _, index) => {
          acc[`date${index}`] = (
            <div className="bar">
              {currencyFormatter(organizedData.bars[index] || 0)}
              <span>
                {currencyFormatter(organizedData.packageValue[index] || 0)}
              </span>
            </div>
          );

          return acc;
        }, {} as Record<string, string | number | React.ReactNode>),
      },
      {
        key: 'Staycation Rate',
        timePeriod: 'Staycation Rate',
        ...organizedData.dates.reduce((acc, _, index) => {
          acc[`date${index}`] = currencyFormatter(
            organizedData.staycationRates[index] || 0
          );

          return acc;
        }, {} as Record<string, string | number | React.ReactNode>),
      },
      {
        key: 'Discount',
        timePeriod: 'Discount',
        ...organizedData.dates.reduce((acc, _, index) => {
          acc[`date${index}`] =
            organizedData.discounts[index] !== 0
              ? `-${organizedData.discounts[index]}%`
              : '';

          return acc;
        }, {} as Record<string, string | number | React.ReactNode>),
      },
    ];
  };

  return (
    <div className="preview-step">
      <div className="explanation">
        Based on your configuration this is what we have identified:
      </div>
      <div className="collapses">
        <Collapse className="collapse-container">
          {filteredRooms &&
            filteredRooms.map((room, idx) => {
              const organizedData = organizePreviewDataByDate(
                room.inventory,
                inventory,
                formData
              );

              const dataSource = formatDataSource(organizedData);

              const columns = [
                {
                  title: (
                    <div className="header-table">
                      <span>Time period</span>
                    </div>
                  ),
                  dataIndex: 'timePeriod',
                  key: 'timePeriod',
                },
                ...organizedData.dates.map((date, index) => ({
                  title: (
                    <div className="header-table date">
                      <span>{format(new Date(date), 'EEE')}</span>
                      {format(new Date(date), 'dd/MM')}
                    </div>
                  ),
                  dataIndex: `date${index}`,
                  key: `date${index}`,
                })),
              ];

              return (
                <Panel
                  header={`${room.categoryName} ${
                    room.name ? `• ${room.name}` : ''
                  }`}
                  key={`${room.categoryName}-${room.name}`}
                >
                  <Table
                    className="preview-table"
                    dataSource={dataSource}
                    columns={columns}
                    pagination={false}
                    rowClassName={(record) =>
                      `${record.key.replace(/\s/g, '').toLowerCase() + '-row'}`
                    }
                  />
                </Panel>
              );
            })}
        </Collapse>
      </div>
      <Alert
        className="preview-alert"
        showIcon
        icon={<InfoCircleOutlined />}
        message={
          <>
            If you validate, Auto inventory will not override data already
            entered manually.
            <br /> You'll still have the power to edit.
          </>
        }
      />
    </div>
  );
};
