export type ISalesResponse = {
  id: number;
  countryId: number;
  startDate: string | Date;
  endDate: string | Date;
  bookableDays: string[];
  extraDays: string[];
  open: boolean;
};

export enum SaleEditType {
  SALE = 'sale',
  EXTRA_DAYS = 'extraDays',
}
