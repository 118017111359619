import { Picture } from 'app/redux/models/Picture/Picture';

import { PAX, SearchFiltersParams } from './filters';

export type EditoActionType = 'search' | 'link';
export type EditoActionTypeForm = EditoActionType | 'empty';

export type SearchFilters = Pick<
  SearchFiltersParams,
  'dates' | 'sort' | 'nightCount' | 'coords'
> & {
  around?: boolean;
  prices?: string[];
  distances?: string[];
  experienceType?: SectionExperienceType;
  accessibility?: string[];
  style?: number[];
  situation?: number[];
  room?: number[];
  experience?: number[];
  pax?: PAX;
  location?: string;
  placeId?: string;
};

export type SectionExperienceType = 'staycation' | 'moment';

type IEditoEvent = {
  id: number;
  countryId: number;
  title: string;
  catchphrase?: string;
  description?: string;
  startDate?: Date;
  endDate?: Date;
  published: boolean;
  publishedAt?: Date;
};

type IEditoEventSection = {
  title: string;
  description?: string;
  actionType?: EditoActionType;
  emojiId?: number;
  label?: string;
  webLink?: string;
  appLink?: string;
};

export type IEditoEventSectionRequest = IEditoEventSection & {
  pictureId?: number;
};

export type IEditoEventSectionResponse = IEditoEventSection & {
  picture?: Picture;
};

export type IEditoEventSectionForm = Omit<
  IEditoEventSectionResponse,
  'actionType'
> & {
  actionType: EditoActionTypeForm;
  searchFilters?: SearchFilters;
};

export type IEditoEventResponse = IEditoEvent & {
  picture?: Picture;
  sections: IEditoEventSectionResponse[];
  clubId?: number;
};

export type IEditoEventRequest = Omit<IEditoEvent, 'startDate' | 'endDate'> & {
  pictureId?: number;
  clubId?: number;
  startDate?: string;
  endDate?: string;
  sections: IEditoEventSectionRequest[];
};

export type IEditoEventForm = IEditoEvent & {
  picture?: Picture;
  sections: IEditoEventSectionForm[];
  clubId?: number | 'empty';
};

export const editoEventFeaturedStatuses = [
  'featured',
  'scheduled',
  'draft',
] as const;
export const editoEventStatuses = [
  'unpublished',
  'published',
  ...editoEventFeaturedStatuses,
] as const;

export const editoEventCatalogStatuses = editoEventStatuses;

export type EditoEventFeaturedStatus =
  (typeof editoEventFeaturedStatuses)[number];
export type EditoEventCatalogStatus = (typeof editoEventStatuses)[number];
export type EditoEventStatus =
  | EditoEventCatalogStatus
  | EditoEventFeaturedStatus;
export type EditoStatus = EditoEventStatus;

export type IEditoEventListResponse = {
  id: number;
  countryId: number;
  clubId?: number;
  title: string;
  startDate?: Date;
  endDate?: Date;
  publishedAt?: Date;
  published: boolean;
  picture?: Picture;
  displayOrder?: number;
  updatedAt: Date;
};

export type IEditoEventList = IEditoEventListResponse & {
  countryLabel: string;
  clubLabel?: string;
  status: EditoEventStatus;
  updatedAt: Date;
};

export type IEditoEventStatusInfo = {
  startDate: Date;
  endDate: Date;
  publishedAt?: Date;
  published: boolean;
};

export type IEditoEventFormStatusInfo = {
  startDate?: Date;
  endDate?: Date;
  publishedAt?: Date;
  published: boolean;
};

export type EditoEventListCategory = 'featured' | 'allEvents';

export type SelectedEventClubId = number | 'all';

export type SearchCriteria =
  | 'dates'
  | 'pax'
  | 'experience'
  | 'distances'
  | 'prices'
  | 'style'
  | 'situation'
  | 'room'
  | 'accessibility'
  | 'sort'
  | 'coords';

export type SearchCriteriaOption = {
  label: string;
  value: SearchCriteria;
};

export type TagCriteria = Extract<
  SearchCriteria,
  'experience' | 'style' | 'situation' | 'room'
>;

export type FilterCategory =
  | 'foodAndDrinks'
  | 'experiences'
  | 'location'
  | 'style'
  | 'room';
