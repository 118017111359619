import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';
import { message } from 'antd';

import { IHotel } from 'app/typings';
import { apiRequest } from 'app/utils/request/api';

const path = '/hotels/:hotelId' as const;

export function useGetHotel(hotelId?: number) {
  return useQuery({
    queryKey: [path],
    queryFn: hotelId
      ? () =>
          apiRequest({ path, params: { hotelId: hotelId?.toString() } }).catch(
            (err) => message.error('Error fetching hotel', err)
          ) as Promise<IHotel>
      : () => Promise.resolve(undefined),
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
    enabled: !!hotelId,
  });
}
