import { useQuery } from '@tanstack/react-query';
import { useMemo } from 'react';

import { RoomListElement } from 'app/typings';
import { apiRequest } from 'app/utils/request/api';
import { SortOrder } from 'app/utils/sort';

const path = '/rooms' as const;

export type RoomsListQueryParam = {
  search?: string;
  pageNumber?: number;
  dateSorting?: SortOrder;
  isPublishedFilter?: boolean[];
};

export function useGetRooms(queryParams: RoomsListQueryParam = {}) {
  const key = useMemo(() => {
    return [
      path,
      {
        queryParams,
      },
    ];
  }, [queryParams]);

  return useQuery({
    queryKey: [key],
    queryFn: () =>
      apiRequest({ path, queryParams }) as Promise<RoomListElement[]>,
  });
}
