import { Button, Form, Layout, Menu, Popconfirm, message } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { SDetailLayout } from 'app/components/StaycationUI';
import Delete from 'app/components/commons/ExtraButtons/Delete/Delete';
import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import {
  resetHotel,
  tryDeleteHotel,
  tryDuplicateHotel,
  tryFetchHotel,
  trySaveHotel,
} from 'app/redux/actions/hotels';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import { Hotel } from 'app/redux/models/Hotel/Hotel';
import { getHotel, getHotelsLoading } from 'app/redux/selectors/hotels';
import { ChannelManagers } from 'app/utils/channelManagers/channelManagers';
import { RateModes } from 'app/utils/channelManagers/rateModes';

import MarketingBrief from '../HotelDetail/MarketingBrief/MarketingBrief';
import Services from '../HotelDetail/Services/Services';
import Spaces from '../HotelDetail/Spaces/Spaces';

import BasicInfo from './BasicInfo';
import Financial from './Financial';
import './HotelDetail.scss';

const { Content } = Layout;

const MENU_ITEMS = [
  { name: 'General' },
  { name: 'Services' },
  { name: 'Spaces' },
  { name: 'Brief' },
];

const MenuCustom = ({ menuItems, selectedMenu, setSelectedMenu }: any) => (
  <Menu
    mode="horizontal"
    onClick={({ key }) => setSelectedMenu(menuItems[parseInt(key, 10)])}
    selectedKeys={[
      menuItems
        .findIndex((item: any) => item.name === selectedMenu.name)
        .toString(),
    ]}
  >
    {menuItems.map((item: any, index: any) => (
      <Menu.Item key={index}>{item.name}</Menu.Item>
    ))}
  </Menu>
);

const HotelEdit = ({ match }: Props) => {
  const hotel = useAppSelector(getHotel);
  const isFetchHotelLoading = useAppSelector(getHotelsLoading);
  const [originalBillingEmails, setOriginalBillingsEmails] = useState(
    hotel?.billingEmails
  );

  const [form] = Form.useForm();

  const {
    params: { id: hotelId },
  } = match;

  const parsedHotelId = parseInt(hotelId || '', 10);

  const [mode, setMode] = useState<'view' | 'edit'>('view');
  const [menu, setMenu] = useState<{ name: string }>(MENU_ITEMS[0]);

  const dispatch = useAppDispatch();

  const toggleMode = useCallback(() => {
    setMode(mode === 'view' ? 'edit' : 'view');
  }, [setMode, mode]);

  useEffect(() => {
    if (!isFetchHotelLoading) {
      setOriginalBillingsEmails(hotel?.billingEmails);
    }
  }, [isFetchHotelLoading, hotel?.billingEmails]);

  useEffect(() => {
    if (!Number.isNaN(parsedHotelId)) {
      dispatch(tryFetchHotel({ hotelId: parsedHotelId }));
    }

    return () => {
      dispatch(resetHotel());
    };
  }, [parsedHotelId, dispatch]);

  const renderMenu = () => (
    <MenuCustom
      menuItems={MENU_ITEMS}
      selectedMenu={menu}
      setSelectedMenu={setMenu}
    />
  );

  const dispatchMenuSection = () => {
    switch (menu.name) {
      case 'Services':
        return <Services hotelId={hotelId} headerMenu={renderMenu()} />;
      case 'Spaces':
        return <Spaces hotelId={hotelId} headerMenu={renderMenu()} />;
      case 'Brief':
        return <MarketingBrief hotelId={hotelId} headerMenu={renderMenu()} />;
      default:
        return null;
    }
  };

  const handleSubmit = useCallback(
    (values: Hotel) => {
      const emails = values.notifEmails.map(({ email }) => email);
      const hasDuplicateEmails = new Set(emails).size !== emails.length;

      if (hasDuplicateEmails) {
        message.error('Duplicate emails not allowed');

        return;
      }

      dispatch(
        trySaveHotel({
          hotel: {
            ...hotel,
            ...values,
            location: {
              ...hotel?.location,
              ...values.location,
            },
            address: {
              ...hotel?.address,
              ...values.address,
              subCity:
                values.address?.subCity === ''
                  ? undefined
                  : values.address?.subCity,
            },
            notifEmails: values.notifEmails,
            channelManager:
              values.channelManager === ChannelManagers.NONE
                ? undefined
                : values.channelManager,
            rateMode:
              values.channelManager === undefined ||
              values.channelManager === ChannelManagers.NONE
                ? undefined
                : values.rateMode || RateModes.MULTI_RATE,
          },
        })
      );

      toggleMode();
    },
    [hotel, toggleMode, dispatch]
  );

  const submitForm = () => form.submit();

  if (!hotel || hotelId !== `${hotel.id}`) {
    return null;
  }

  const renderDeleteButton = () => (
    <Delete
      entityDescription={`Hotel #${hotelId}`}
      onDelete={() => dispatch(tryDeleteHotel({ hotelId: parsedHotelId }))}
    />
  );

  const renderDuplicateButton = () => (
    <div className="duplicate-btn">
      <Popconfirm
        title={`Are you sure you want to duplicate this hotel ? (This action is irrevocable)`}
        placement="bottomRight"
        onConfirm={() =>
          dispatch(tryDuplicateHotel({ hotelId: parsedHotelId }))
        }
        okText="Yes"
        cancelText="No"
      >
        <Button size="large">Duplicate</Button>
      </Popconfirm>
    </div>
  );

  return (
    <Layout className="hotel-detail">
      <FormLegacyRenderProp
        form={form}
        onReset={() => form.resetFields()}
        onFinish={handleSubmit}
        initialValues={hotel}
      >
        {(values, { resetFields, getFieldsError, setFieldsValue }) =>
          menu.name === 'General' ? (
            <SDetailLayout
              isDirty
              title={hotel.name}
              mode={mode}
              onEdit={toggleMode}
              onSave={submitForm}
              onCancel={toggleMode}
              reset={resetFields}
              headerMenu={renderMenu()}
              saveDisabled={
                !getFieldsError().every(({ errors }) => errors.length === 0) ||
                values.notifEmails.every(
                  ({ bookingConfirmation }) => !bookingConfirmation
                )
              }
              headerExtra={[renderDuplicateButton(), renderDeleteButton()]}
            >
              <Content className="hotel-detail__content">
                <BasicInfo
                  mode={mode}
                  values={values}
                  setFieldsValue={setFieldsValue}
                  originalBillingEmails={originalBillingEmails}
                />
                <Financial mode={mode} />
              </Content>
            </SDetailLayout>
          ) : (
            dispatchMenuSection()
          )
        }
      </FormLegacyRenderProp>
    </Layout>
  );
};

export type Props = {
  match: {
    params: {
      id?: string;
    };
  };
};

export default HotelEdit;
