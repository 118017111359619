import { LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Table } from 'antd';

import { Country } from 'app/redux/models/Country/Country';
import { HotelItem } from 'app/redux/models/HotelItem/HotelItem';
import { GestureListItem } from 'app/typings/CommercialGestures/CommercialGestureListItem';
import { dateAndHour } from 'app/utils/dates';
import { scrollToTop } from 'app/utils/scroll';
import { SortOrder, convertFromAntdSortOrder } from 'app/utils/sort';

import { GesturesQueryParam, PAGE_SIZE } from '../CommercialGestures';

type Props = {
  hotels: Array<HotelItem>;
  countries: Array<Country>;
  updateQueryParam: any;
  queryParam: Partial<GesturesQueryParam>;
  loading: boolean;
  commercialGestures?: GestureListItem[];
};

const { Column } = Table;

const CommercialGestureList = ({
  hotels,
  countries,
  updateQueryParam,
  queryParam,
  loading,
  commercialGestures,
}: Props) => {
  const formatAmount = (number: number, row: GestureListItem) => {
    const hotel = hotels.find((h) => h.id === row.hotelId);
    const country = countries.find((c) => c.id === hotel?.countryId);

    return country ? `${number} ${country.currency}` : number;
  };

  const handleOffset = (x: number) => {
    updateQueryParam({ pageNumber: (queryParam?.pageNumber || 1) + x });

    scrollToTop();
  };

  const dateSortingHandler = (sort: SortOrder) => {
    updateQueryParam({
      dateSorting: sort,
      pageNumber: 1,
    });
  };

  const handleSearch = (search: string) => {
    updateQueryParam({
      search: search,
      pageNumber: 1,
    });
  };

  const pagination = (className: string) => (
    <div className={className}>
      <Button
        disabled={queryParam.pageNumber === 1}
        onClick={() => handleOffset(-1)}
      >
        <LeftOutlined />
      </Button>
      <Button>{queryParam.pageNumber}</Button>
      <Button
        disabled={commercialGestures && commercialGestures.length < PAGE_SIZE}
        onClick={() => handleOffset(1)}
      >
        <RightOutlined />
      </Button>
    </div>
  );

  const header = () => (
    <div className="body-header">
      <div className="left">
        <Input
          placeholder="Search..."
          value={queryParam.search}
          onChange={(event) => handleSearch(event.target.value)}
          suffix={<SearchOutlined />}
          className="search"
          size="large"
        />
      </div>
      {pagination('button-wrapper')}
    </div>
  );

  return (
    <>
      <Table<GestureListItem>
        rowKey="id"
        loading={loading}
        dataSource={commercialGestures}
        pagination={false}
        title={header}
        onChange={(__, ___, sorter) => {
          // @ts-ignore
          const { order } = sorter;

          dateSortingHandler(convertFromAntdSortOrder(order));
        }}
      >
        <Column key="name" title="Hotel" dataIndex="hotelName" />
        <Column
          key="createdAt"
          title="Creation Date"
          dataIndex="createdAt"
          render={dateAndHour}
          sorter
        />
        <Column
          key="amount"
          title="Amount"
          dataIndex="amount"
          render={formatAmount}
        />
        <Column key="comment" title="Comment" dataIndex="comment" />
      </Table>
      {pagination('footer')}
    </>
  );
};

export default CommercialGestureList;
