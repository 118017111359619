import { MILLISECONDS_IN_MINUTE } from '@stdlib/constants-time';
import { useQuery } from '@tanstack/react-query';

import { Booking } from 'app/typings/bookings';
import { apiRequest } from 'app/utils/request/api';

const path = '/hotel-admin/bookings/:code' as const;

export function useBookingDetail(code?: string) {
  return useQuery({
    queryKey: [path, { code }],
    queryFn: () =>
      !!code
        ? (apiRequest({
            path,
            params: { code },
          }) as Promise<Booking>)
        : undefined,
    enabled: !!code,
    staleTime: 5 * MILLISECONDS_IN_MINUTE,
  });
}
