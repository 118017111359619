import { Button, Form, Layout, Steps } from 'antd';
import { validate as isValidEmail } from 'email-validator';
import { useEffect, useState } from 'react';

import { BOOKING_COM_URL_REGEXP } from 'app/components/commons/Hotel/MarketingBrief/HotelLinks/HotelLinks';
import {
  resetNewHotel,
  trySaveHotelAndRedirect,
  updateNewHotel,
} from 'app/redux/actions/hotels';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import type { NewHotel as NewHotelType } from 'app/redux/models/NewHotel/NewHotel';
import {
  selectClubs,
  selectCountries,
  selectHotelGroups,
} from 'app/redux/selectors/conf';
import { getNewHotel } from 'app/redux/selectors/hotels';
import { ChannelManagers } from 'app/utils/channelManagers/channelManagers';
import { RateModes } from 'app/utils/channelManagers/rateModes';

import FirstStep from './FirstStep/FirstStep';
import FourthStep from './FourthStep/FourthStep';
import './NewHotel.scss';
import SecondStep from './SecondStep/SecondStep';
import ThirdStep from './ThirdStep/ThirdStep';
import { isSubCityRequired } from './utils';

type StepType = 0 | 1 | 2 | 3;

const { Content, Header } = Layout;
const { Step } = Steps;

const STEP_NUMBER = 4;
const BRIEF_PREFIX = 'brief';

const INITIAL_VALUE = {
  notifEmails: [{ email: '', role: undefined }],
};

const isStep1Completed = (hotel: Partial<NewHotelType> | null) => {
  if (!hotel) {
    return false;
  }

  const subCityRequired =
    hotel?.address?.city && isSubCityRequired(hotel.address?.city);

  return !!(
    hotel.name &&
    (hotel.stars || hotel.stars === 0) &&
    hotel.groupId &&
    hotel.phone &&
    hotel.address &&
    hotel.address.street &&
    hotel.address.city &&
    hotel.address.department &&
    hotel.address.region &&
    hotel.address.zipCode &&
    hotel.address.countryId &&
    hotel.location?.coords?.lat &&
    hotel.location?.coords?.lng &&
    (!subCityRequired || hotel.address.subCity)
  );
};

const isStep2Completed = (hotel: Partial<NewHotelType> | null) => {
  if (!hotel || !hotel.notifEmails) {
    return false;
  }

  const hasDuplicateEmails = (emails: string[]) => {
    const emailSet = new Set(emails);

    return emailSet.size !== emails.length;
  };

  try {
    const emails = hotel.notifEmails.map((contact) => contact.email);

    if (
      !emails.every(isValidEmail) ||
      emails.some((email) => !email) ||
      hasDuplicateEmails(emails)
    ) {
      return false;
    }

    return true;
  } catch (error) {
    return false;
  }
};

const isStep3Completed = (hotel: Partial<NewHotelType> | null) =>
  !!(hotel && hotel.experiences && hotel.experiences.length > 0);

const isStep4Completed = (hotel: Partial<NewHotelType> | null) => {
  if (!hotel) {
    return false;
  }

  return !!(
    hotel.brief?.websiteUrl &&
    hotel.brief?.bookingUrl &&
    (!hotel.brief?.bookingComUrl ||
      hotel.brief?.bookingComUrl.match(BOOKING_COM_URL_REGEXP))
  );
};

const isNewHotelFilled = (
  hotel: Partial<NewHotelType> | null
): hotel is NewHotelType =>
  isStep1Completed(hotel) &&
  isStep2Completed(hotel) &&
  isStep3Completed(hotel) &&
  isStep4Completed(hotel);

type Props = {
  history: {
    goBack: () => void;
  };
};

export const NewHotel = ({ history }: Props) => {
  const dispatch = useAppDispatch();
  const clubs = useAppSelector(selectClubs);
  const countries = useAppSelector(selectCountries);
  const hotelGroups = useAppSelector(selectHotelGroups);
  const [step, setStep] = useState<StepType>(0);
  const newHotel = useAppSelector(getNewHotel);
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    dispatch(resetNewHotel());
  }, [dispatch]);

  const getIsStepCompleted = () => {
    const stepCompletedMap = {
      0: isStep1Completed,
      1: isStep2Completed,
      2: isStep3Completed,
      3: isStep4Completed,
    };

    return stepCompletedMap[step](newHotel);
  };

  const onValuesChange = (_: any, values: Partial<NewHotelType>) => {
    dispatch(updateNewHotel({ newHotel: values }));
    form.setFieldsValue(values);
  };

  const isStepCompleted = getIsStepCompleted();

  const onGetLucky = (data: Partial<NewHotelType>) => {
    dispatch(updateNewHotel({ newHotel: data }));
    form.setFieldsValue(data);
  };

  const submitNewHotel = () => {
    if (!isNewHotelFilled(newHotel)) {
      return;
    }

    setIsLoading(true);

    const values = {
      ...newHotel,
      notifEmails: newHotel.notifEmails?.map((contact) => ({
        email: contact.email.toLowerCase(),
        bookingConfirmation: true,
        stock: true,
        billing: false,
        role: contact.role,
      })),
      new: true,
      channelManager:
        newHotel.channelManager === ChannelManagers.NONE
          ? undefined
          : newHotel.channelManager,
      rateMode:
        newHotel.channelManager && newHotel.channelManager !== 'NONE'
          ? newHotel.rateMode || RateModes.MULTI_RATE
          : undefined,
    };

    dispatch(trySaveHotelAndRedirect({ hotel: values }));
  };

  const renderStep = () => {
    if (step === 0) {
      return (
        <FirstStep
          initializeForm={onGetLucky}
          hotelGroups={hotelGroups}
          clubs={clubs}
          countries={countries}
        />
      );
    }

    if (step === 1) {
      return (
        <SecondStep
          onValuesChange={onValuesChange}
          values={form.getFieldsValue()}
        />
      );
    }

    if (step === 2) {
      return <ThirdStep onValuesChange={onValuesChange} />;
    }

    return <FourthStep prefix={BRIEF_PREFIX} form={form} />;
  };

  const onMoveStep = (operation: 'next' | 'previous') => () => {
    const newStep = operation === 'next' ? 1 : -1;

    setStep((currentStep) => (currentStep + newStep) as StepType);
  };

  return (
    <Layout className="page__container new-hotel-form">
      <Header className="page__form-header align-items-center">
        {step === 0 ? (
          <Button onClick={() => history.goBack()}>Cancel</Button>
        ) : (
          <Button onClick={onMoveStep('previous')}>Back</Button>
        )}
        <h2 className="title">New hotel</h2>
        {step < STEP_NUMBER - 1 ? (
          <Button
            onClick={onMoveStep('next')}
            disabled={!isStepCompleted}
            type="primary"
          >
            Next
          </Button>
        ) : (
          <Button
            onClick={() => form.submit()}
            loading={isLoading}
            disabled={!isStepCompleted || isLoading}
            type="primary"
          >
            Create
          </Button>
        )}
      </Header>
      <Content className="body">
        <Steps current={step} className="steps">
          {Array(STEP_NUMBER)
            .fill(null)
            .map((val, index) => (
              <Step key={index} className="step" />
            ))}
        </Steps>
        <Form
          form={form}
          onFinish={submitNewHotel}
          onValuesChange={onValuesChange}
          initialValues={INITIAL_VALUE}
        >
          {renderStep()}
        </Form>
      </Content>
    </Layout>
  );
};

export default NewHotel;
