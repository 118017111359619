export enum ModifierType {
  FLAT = 'FLAT',
  RATE = 'RATE',
}

export type IAutoInventoryPayload = {
  roomMappings: (
    | {
        id: number;
        scrappedRoomId?: number;
        scrappedRoomName?: string;
      }
    | undefined
  )[];
  discount?: number;
  barModifier?: {
    value: number;
    modifierType: ModifierType;
  };
  lastMinutePricingRule?: {
    discount: number;
    days: number;
  };
};

export type RoomScrapped = {
  roomId: number;
  roomName: string;
};

export type InventoryScrapped = {
  date: string;
  stock: number;
  price: number;
};

export type RoomsWithInventory = RoomScrapped & {
  inventory: InventoryScrapped[];
};

export enum SyncStatus {
  Idle,
  InProgress,
  Done,
  Failed,
}
