import { Coordinates } from 'app/redux/models/Coordinates/Coordinates';

export type NightCount = 0 | 1 | 2;

export type PAX = {
  adults: number;
  children: number;
  babies: number;
};

export type PackageFilters = PAX & {
  dates: string[];
  prices: Range[];
  distances: Range[];
  tagIds: number[];
  hotelServices: number[];
  pmr?: boolean;
  sort?: SortFilter;
  nightCount: NightCount;
};

export type Range = {
  start?: number;
  end?: number;
};

export const SORT = {
  BEST: 'BEST',
  CHEAPEST: 'CHEAPEST',
  CLOSEST: 'CLOSEST',
  NEWEST: 'NEWEST',
  RATING: 'RATING',
} as const;

export type SortFilter = (typeof SORT)[keyof typeof SORT];

export type SearchFiltersParams = Partial<PackageFilters> & {
  coords?: Coordinates;
  around?: boolean;
};
