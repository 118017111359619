import { message } from 'antd';
import { push } from 'connected-react-router';
import _ from 'lodash';
import {
  initialize,
  setSubmitFailed,
  setSubmitSucceeded,
  startSubmit,
  stopSubmit,
} from 'redux-form';
import { all, call, put, select, takeEvery } from 'redux-saga/effects';

import * as entitiesActions from 'app/redux/actions/entities';
import { get, patch, post, requestGenerator } from 'app/redux/requests';

const entitiesURL = {
  room: 'rooms',
  roomFeatures: 'rooms/features',
  roomCategories: 'rooms/categories',
  bedCategories: 'rooms/beds/categories',

  experience: 'experiences',
  experienceCategories: 'experiences/categories',

  hotel: 'hotels',
  hotelTaxes: 'hotels/taxes',
  hotelGroups: 'hotels/groups',
  hotelGalleries: 'hotels/galleries',
  hotelPictures: 'hotels/pictures ',
  hotelGallery: 'hotels/gallery',
  hotelSpaces: 'hotels/spaces',
  spaceTypes: 'hotels/spaces/types',

  package: 'packages',

  bookings: 'admin/bookings',
  bookingDetails: 'admin/bookings',
  giftCards: 'admin/gift-cards',
  giftCardDetails: 'admin/gift-cards',

  merchPackages: 'merch/packages',

  guide: 'guides',
  guideCategories: 'guides/categories',
  collection: 'collections',

  emojis: 'pictures/emojis',

  users: 'admin/users',
  userDetails: 'admin/users',
  voucher: 'vouchers',
};

export function* getEntity({ entity, entityId }) {
  return yield requestGenerator(
    () => put(entitiesActions.startGetEntity(entity, entityId)),
    () => get(`${_.get(entitiesURL, entity, entity)}/${entityId}`),
    (result) => put(entitiesActions.getEntitySuccess(entity, entityId, result)),
    (error) => put(entitiesActions.getEntityFailure(entity, entityId, error))
  );
}

export function* fetchEntity({ entity }) {
  return yield requestGenerator(
    () => put(entitiesActions.startFetchEntity(entity)),
    () => get(_.get(entitiesURL, entity, entity)),
    (result) => put(entitiesActions.fetchEntitySuccess(entity, result)),
    (error) => put(entitiesActions.fetchEntityFailure(entity, error))
  );
}

export function* fetchEntityWithParams({ entity, params }) {
  return yield requestGenerator(
    () => put(entitiesActions.startFetchEntity(entity)),
    () => get(`${_.get(entitiesURL, entity, entity)}${params}`),
    (result) =>
      put(entitiesActions.fetchEntitySuccessWithParams(entity, result)),
    (error) => put(entitiesActions.fetchEntityFailure(entity, error))
  );
}

export function* saveEntity({ entity, values, redirectToEdit }) {
  yield requestGenerator(
    () =>
      all([
        put(startSubmit(entity)),
        put(entitiesActions.startSaveEntity(entity)),
      ]),
    () => post(_.get(entitiesURL, entity, entity), values),
    (entityId) =>
      all([
        put(stopSubmit(entity)),
        put(setSubmitSucceeded(entity)),
        put(
          entitiesActions.saveEntitySuccess(entity, entityId, redirectToEdit)
        ),
        message.success(`Saved ${entity} #${entityId}!`),
      ]),
    (error, status) =>
      all([
        put(stopSubmit(entity, error)),
        put(setSubmitFailed(entity, error)),
        put(entitiesActions.saveEntityFailure(entity, error)),
        call(
          message.error,
          status === 409 ? 'Slug already exists' : `Failed to save ${entity}`,
          10
        ), // TODO: when error codes are handled better, print error here (and send a slack?)
      ]),
    { expectJson: false }
  );
}

export function* publishEntity({ entity, entityId }) {
  yield requestGenerator(
    () => put(entitiesActions.startPatchEntity(entity)),
    () => patch(`${_.get(entitiesURL, entity, entity)}/${entityId}/publish`),
    () => {
      message.success(`Published ${entity} #${entityId}!`);

      return put(entitiesActions.patchEntitySuccess(entity));
    },
    (error) => {
      message.error(`Failed to publish ${entity} #${entityId}!`);

      return put(entitiesActions.patchEntityFailure(entity, error));
    }
  );
}

export function* hideEntity({ entity, entityId }) {
  yield requestGenerator(
    () => put(entitiesActions.startPatchEntity(entity)),
    () => patch(`${_.get(entitiesURL, entity, entity)}/${entityId}/hide`),
    ({ hidden }) => {
      message.success(`${entity} #${entityId} ${hidden ? 'hidden' : 'shown'}!`);

      return put(entitiesActions.patchEntitySuccess(entity));
    },
    (error) => {
      message.error(`Failed to patch ${entity} #${entityId}!`);

      return put(entitiesActions.patchEntityFailure(entity, error));
    }
  );
}

export function* openPreview({ entityPath }) {
  const appUrl = yield select((state) => state.navigation.appUrl);

  yield window.open(`${appUrl}/${entityPath}`);
}

export function* handleSaveSuccess({ entity, entityId, redirectToEdit }) {
  const entityUrl = _.get(entitiesURL, entity, entity);

  if (redirectToEdit === 'entity') {
    yield call(getEntity, { entity: entityUrl, entityId });
    yield put(push(`/${entityUrl}/${entityId}/edit`));
  } else if (redirectToEdit === 'list') {
    const entities = yield call(fetchEntity, { entity: entityUrl });
    const updatedEntity = _.find(entities, { id: +entityId });

    if (updatedEntity) {
      yield put(initialize(entity, updatedEntity));
    }

    yield put(push(`/${entityUrl}`));
  }
}

export default function* entitiesSagas() {
  yield all([
    call(fetchEntity, { entity: 'countries' }),
    takeEvery(entitiesActions.actions.TRY_GET_ENTITY, getEntity),
    takeEvery(entitiesActions.actions.TRY_FETCH_ENTITY, fetchEntity),
    takeEvery(
      entitiesActions.actions.TRY_FETCH_ENTITY_WITH_PARAMS,
      fetchEntityWithParams
    ),
    takeEvery(entitiesActions.actions.TRY_SAVE_ENTITY, saveEntity),
    takeEvery(entitiesActions.actions.SAVE_ENTITY_SUCCESS, handleSaveSuccess),
    takeEvery(entitiesActions.actions.PATCH_ENTITY_SUCCESS, fetchEntity),
    takeEvery(entitiesActions.actions.OPEN_PREVIEW, openPreview),
    takeEvery(entitiesActions.actions.TRY_PUBLISH_ENTITY, publishEntity),
    takeEvery(entitiesActions.actions.TRY_HIDE_ENTITY, hideEntity),
  ]);
}
