import {
  EllipsisOutlined,
  LeftOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Input, Layout, Menu, PageHeader, Table } from 'antd';
import fileDownload from 'js-file-download';
import { debounce } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo } from 'react';

import { renderBillsColumnStatus } from 'app/components/commons/Bills/Bills';
import { PriceCell } from 'app/components/lists/cells';
import ModalCheckBox from 'app/components/pages/Extranet/ExtranetBookings/ModalCheckBox';
import {
  TryFetchBillsPayload,
  downloadBill,
  resetBillObjectPdf,
  tryFetchBills,
} from 'app/redux/actions/bills';
import { useAppDispatch, useAppSelector } from 'app/redux/hooks';
import {
  adyenValue,
  isProvider,
  stripeValue,
} from 'app/redux/models/Bill/Bill';
import type { Bill, Provider } from 'app/redux/models/Bill/Bill';
import {
  getBillPdfObject,
  getBills,
  getIsDownloading,
  getIsLoading,
} from 'app/redux/selectors/bills';
import { PayoutStatus } from 'app/typings/bills';
import {
  parseArrayParam,
  useQueryParamHistory,
} from 'app/utils/queryParamHistory';
import { scrollToTop } from 'app/utils/scroll';
import {
  SortOrder,
  convertFromAntdSortOrder,
  convertToAntdSortOrder,
  formatSortingForAPI,
  getSortOrder,
  isValidSortOrder,
} from 'app/utils/sort';

import './Bills.scss';

type BillsQueryParam = {
  search: string;
  pageNumber: number;
  dateSorting: SortOrder;
  provider: Provider[];
};

const { Content } = Layout;
const { Column } = Table;

const PAGE_SIZE = 50;

const getProvider = (maybeProvider: string): Provider | undefined => {
  return isProvider(maybeProvider) ? maybeProvider : undefined;
};

const providerDefaultValue: Provider[] = [adyenValue, stripeValue];
const queryParamInit = {
  pageNumber: 1,
  dateSorting: 'descend',
  provider: providerDefaultValue,
} as const;
const queryParamKeys = {
  pageNumber: 'p',
  dateSorting: 'ds',
  provider: 'pr',
  search: 's',
};
const queryParamFormatter = {};
const queryParamExtractor = {
  pageNumber: parseInt,
  dateSorting: getSortOrder,
  provider: parseArrayParam(getProvider),
};

const formatProvider = (provider?: Provider[]) => {
  return provider?.length === 1 ? provider[0] : undefined;
};

const formatDate = (date: any) => {
  return date && moment.utc(date).local().format('DD/MM/YYYY à HH:mm');
};

const makePayload = (
  queryParam: Partial<BillsQueryParam>
): TryFetchBillsPayload => {
  const validSortOrder = isValidSortOrder(queryParam.dateSorting);

  return {
    search: queryParam.search,
    sortingColumn: validSortOrder ? 'createdAt' : undefined,
    sortingOrder: validSortOrder
      ? formatSortingForAPI(queryParam.dateSorting)
      : undefined,
    provider: formatProvider(queryParam.provider),
    offset: ((queryParam.pageNumber || 1) - 1) * PAGE_SIZE,
    limit: PAGE_SIZE,
  };
};

export const Bills = () => {
  const dispatch = useAppDispatch();
  const bills = useAppSelector(getBills);
  const isLoading = useAppSelector(getIsLoading);
  const billPdfObject = useAppSelector(getBillPdfObject);
  const isDownloading = useAppSelector(getIsDownloading);

  const { queryParam, updateQueryParam } =
    useQueryParamHistory<BillsQueryParam>(
      queryParamInit,
      queryParamKeys,
      queryParamFormatter,
      queryParamExtractor
    );

  const debounceFetch = useMemo(
    () =>
      debounce(
        (params: TryFetchBillsPayload) => dispatch(tryFetchBills(params)),
        500
      ),
    [dispatch]
  );

  const handleDownload = useCallback(
    (billId: number) => {
      if (!isDownloading) {
        dispatch(downloadBill({ billId }));
      }
    },
    [dispatch, isDownloading]
  );

  useEffect(() => {
    const payload = makePayload(queryParam);

    debounceFetch(payload);
  }, [queryParam, debounceFetch]);

  useEffect(() => {
    if (billPdfObject) {
      fileDownload(
        billPdfObject.pdf,
        billPdfObject.filename,
        'application/pdf'
      );
      dispatch(resetBillObjectPdf());
    }
  }, [billPdfObject, dispatch]);

  const handleOffset = (x: number) => {
    updateQueryParam({ pageNumber: (queryParam?.pageNumber || 1) + x });

    scrollToTop();
  };

  const dateSortingHandler = (sort: SortOrder) => {
    updateQueryParam({
      dateSorting: sort,
      pageNumber: 1,
    });
  };

  const handleSearch = (search: string) => {
    updateQueryParam({
      search: search,
      pageNumber: 1,
    });
  };

  const handleProviderQuery = (providers: Provider[]) => {
    updateQueryParam({
      provider: providers,
      pageNumber: 1,
    });
  };

  const pagination = (className: any) => (
    <div className={className}>
      <Button
        disabled={queryParam.pageNumber === 1}
        onClick={() => handleOffset(-1)}
      >
        <LeftOutlined />
      </Button>
      <Button>{queryParam.pageNumber}</Button>
      <Button disabled={bills.length < 50} onClick={() => handleOffset(1)}>
        <RightOutlined />
      </Button>
    </div>
  );

  const header = () => (
    <div className="body-header">
      <div className="left">
        <Input
          placeholder="Search..."
          value={queryParam.search}
          onChange={(event) => handleSearch(event.target.value)}
          suffix={<SearchOutlined />}
          className="search"
          size="large"
        />
      </div>
      {pagination('button-wrapper')}
    </div>
  );

  const handleProviderFilter = (providers: any) => {
    handleProviderQuery(providers.length ? providers : providerDefaultValue);
  };

  const handleRowActions = ({ id }: any) => ({
    onClick: () => handleDownload(id),
  });

  const actionMenu = ({ id }: any) => (
    <div className="actions-menu">
      <Dropdown
        overlay={
          <Menu>
            <Menu.Item onClick={() => handleDownload(id)}>Download</Menu.Item>
          </Menu>
        }
        trigger={['click']}
      >
        <EllipsisOutlined rotate={90} />
      </Dropdown>
    </div>
  );

  return (
    <Layout className="bills-list">
      <PageHeader className="header" title="Bills" />
      <Content className="body">
        <Table
          dataSource={bills}
          pagination={false}
          title={header}
          loading={isLoading}
          rowKey="id"
          onRow={handleRowActions}
          onChange={(__, ___, sorter) => {
            // @ts-ignore
            const { order } = sorter;

            dateSortingHandler(convertFromAntdSortOrder(order));
          }}
        >
          <Column title="Hotel" dataIndex="hotelName" />
          <Column title="Invoice name #" dataIndex="reference" />
          <Column
            title="Total net amount"
            dataIndex="totalAmount"
            render={(price, entity) => (
              <PriceCell price={price} entity={entity} defaultText="Missing" />
            )}
          />
          <Column
            title="Total comission"
            dataIndex="commissionAmount"
            render={(price, entity) => (
              <PriceCell price={price} entity={entity} defaultText="Missing" />
            )}
          />
          <Column
            title="Creation date"
            dataIndex="createdAt"
            sorter
            sortOrder={convertToAntdSortOrder(queryParam.dateSorting)}
            render={formatDate}
          />

          <Column
            title="Period"
            render={({ fromDate, toDate }) => {
              if (fromDate && toDate) {
                const from = moment(fromDate).format('YYYY-MM-DD');
                const to = moment(toDate).format('YYYY-MM-DD');

                return `${from} - ${to}`;
              }

              return null;
            }}
          />
          <Column
            title={
              <ModalCheckBox
                title="Provider"
                defaultValue={providerDefaultValue}
                onChange={handleProviderFilter}
                items={[
                  {
                    value: adyenValue,
                    label: 'Adyen',
                  },
                  {
                    value: stripeValue,
                    label: 'Stripe',
                  },
                ]}
                value={queryParam.provider}
              />
            }
            dataIndex="provider"
          />
          <Column
            title="Payout Status"
            dataIndex="status"
            render={(status: PayoutStatus, bill: Bill) =>
              renderBillsColumnStatus(status, bill, false)
            }
          />
          <Column
            render={actionMenu}
            onCell={() => ({ onClick: (e) => e.stopPropagation() })}
            align="center"
          />
        </Table>
        {pagination('footer')}
      </Content>
    </Layout>
  );
};

export default Bills;
