import { all } from 'redux-saga/effects';

import authSaga from './auth';
import billsSaga from './bills';
import bookingSaga from './booking';
import bookingsSaga from './bookings';
import collectionsSaga from './collections';
import confSaga from './conf';
import customTagsSaga from './customTags';
import entitiesSaga from './entities';
import experiencesSaga from './experiences';
import formsSaga from './forms';
import giftCardSaga from './giftCard';
import guidesSaga from './guides';
import hotelAdminSaga from './hotelAdmin';
import hotelsSaga from './hotels';
import navigationSaga from './navigation';
import openingsSaga from './openings';
import packagesSaga from './packages';
import picturesSaga from './pictures';
import roomsSaga from './rooms';
import tagsSaga from './tags';
import usersSaga from './users';
import vouchersSaga from './vouchers';

export default function* sagas() {
  yield all([
    entitiesSaga(),
    navigationSaga(),
    collectionsSaga(),
    openingsSaga(),
    hotelsSaga(),
    guidesSaga(),
    formsSaga(),
    hotelAdminSaga(),
    giftCardSaga(),
    authSaga(),
    billsSaga(),
    bookingSaga(),
    bookingsSaga(),
    roomsSaga(),
    picturesSaga(),
    confSaga(),
    customTagsSaga(),
    packagesSaga(),
    experiencesSaga(),
    tagsSaga(),
    usersSaga(),
    vouchersSaga(),
  ]);
}
