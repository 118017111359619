import { Form, Input, InputNumber } from 'antd';

import { Option, Select } from 'app/components/fields/Select';
import { Country } from 'app/redux/models/Country/Country';
import { HotelItem } from 'app/redux/models/HotelItem/HotelItem';

import { getCurrency } from '../CommercialGestures';

type Props = {
  hotels: Array<HotelItem>;
  countries: Array<Country>;
  onHotelChange: (hotelId: number) => void;
};

const RULES = [{ required: true, message: 'Required' }];

const CommercialGestureForm = ({ hotels, countries, onHotelChange }: Props) => {
  return (
    <>
      <Form.Item name="hotelId" label="Recipient name" rules={RULES}>
        <Select
          placeholder="Select a hotel"
          showSearch
          optionFilterProp="children"
          onChange={onHotelChange}
        >
          {hotels.map((h) => (
            <Option key={h.id} value={h.id}>
              {h.name} - {getCurrency(countries, h.countryId)}
            </Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item
        name="amount"
        label="Amount"
        rules={[
          {
            required: true,
            min: 0,
            type: 'number',
            message: 'Required',
          },
        ]}
      >
        <InputNumber placeholder="10, 20, 30..." />
      </Form.Item>
      <Form.Item name="comment" label="Comment" rules={RULES}>
        <Input.TextArea rows={3} />
      </Form.Item>
    </>
  );
};

export default CommercialGestureForm;
