import { Form, Radio, Select } from 'antd';
import React, { useContext, useState } from 'react';

import CurrencyContext from 'app/components/commons/Currency/CurrencyContext/CurrencyContext';
import CustomInput from 'app/components/fields/Input';
import { ModifierType } from 'app/typings/autoInventory';
import { parseInputNumber } from 'app/utils/typing';

import { FormValues } from '../../../Utils';

import './BAR.scss';

const RULES = [{ required: true, message: 'Required' }];

type Props = {
  values: FormValues;
};

export const BAR = ({ values }: Props) => {
  const { currencySymbol } = useContext(CurrencyContext);
  const [value, setValue] = useState<boolean | undefined>(values.matchOTAPrice);

  return (
    <div className="bar-step">
      <div className="explanation">
        Help us calculate your BAR based on the pricing rule you applied on
        Booking.com.
      </div>
      <div className="pricing-rule">
        <Form.Item name={'matchOTAPrice'} noStyle>
          <Radio.Group
            onChange={(e) => setValue(e.target.value)}
            value={value}
            className="pricing-rule-choice"
          >
            <Radio value={true}>
              My BAR RO (NA/NR) is equal to my OTA price
            </Radio>
            <div className="with-custom-form">
              <div className="radio">
                <Radio value={false}>
                  My BAR RO (NA/NR) is not equal to my OTA price
                </Radio>
              </div>
              {value === false && (
                <div className="bar-form">
                  <div className="form">
                    <div>Booking.com Price* =</div>
                    <div>BAR</div>
                    <div>+</div>
                    <Form.Item
                      name={['customBar', 'value']}
                      rules={RULES}
                      noStyle
                    >
                      <CustomInput
                        onWheel={(e) => e.currentTarget.blur()}
                        min={0}
                        parser={parseInputNumber}
                      />
                    </Form.Item>
                    <Form.Item
                      name={['customBar', 'suffix']}
                      rules={RULES}
                      noStyle
                    >
                      <Select>
                        <Select.Option value={ModifierType.RATE}>
                          %
                        </Select.Option>
                        <Select.Option value={ModifierType.FLAT}>
                          {currencySymbol}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </div>
                  <div className="info">*This is the non-genius price</div>
                </div>
              )}
            </div>
          </Radio.Group>
        </Form.Item>
      </div>
    </div>
  );
};
