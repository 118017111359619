import { WarningOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Popconfirm } from 'antd';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import { addDays, isBefore } from 'date-fns';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';

import { FormLegacyRenderProp } from 'app/components/forms/FormLegacyRenderProp';
import { useSaveSales, useToggleSaleOpen } from 'app/hooks/data/useSales';
import { ISalesResponse, SaleEditType } from 'app/typings/sales';

import MultipleDatePicker from '../../Collections/CollectionDetail/CollectionSections/MultipleDatePicker/MultipleDatePicker';

import './SaleEditModal.scss';

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

const DATE_FORMAT = 'eeee DD MMMM HH:mm';

type SaleEditModalProps = {
  sale: ISalesResponse;
  editType?: SaleEditType;
  onClose: () => void;
};

const SaleEditModal = ({ sale, editType, onClose }: SaleEditModalProps) => {
  const [form] = Form.useForm();

  const { mutate: saveSales, isLoading } = useSaveSales();
  const { mutate: toggleSaleOpen, isLoading: isTogglingSaleOpen } =
    useToggleSaleOpen(sale.id);

  const handleSubmit = (values: ISalesResponse) => {
    const formattedValues = {
      ...sale,
      ...values,
    };

    saveSales(formattedValues);
    onClose();
  };

  const handleToggleSaleOpen = () => {
    toggleSaleOpen();
    onClose();
  };

  const initialValues = {
    startDate: new Date(sale.startDate),
    endDate: new Date(sale.endDate),
    bookableDays: sale.bookableDays,
    extraDays: sale.extraDays,
  };

  return (
    <FormLegacyRenderProp
      form={form}
      onFinish={handleSubmit}
      initialValues={initialValues}
      preserve={false}
    >
      {(values) => (
        <Modal
          width={800}
          open={!!editType}
          okText="Save"
          okButtonProps={{ loading: isLoading }}
          onOk={form.submit}
          onCancel={onClose}
          title={
            editType === 'sale'
              ? 'Edit current sale'
              : 'Edit extra inventory days'
          }
        >
          {editType === 'sale' ? (
            <>
              <Form.Item name="startDate" label="Start">
                <DatePicker
                  showTime
                  showToday
                  showSecond={false}
                  format={DATE_FORMAT}
                  disabled
                />
              </Form.Item>
              <Form.Item name="endDate" label="End">
                <DatePicker
                  showTime
                  showToday
                  showSecond={false}
                  format={DATE_FORMAT}
                  disabledDate={(d) =>
                    !d || isBefore(d, addDays(new Date(), 1))
                  }
                />
              </Form.Item>

              <Form.Item label="Bookable days" name="bookableDays">
                <MultipleDatePicker value={values.bookableDays} />
              </Form.Item>

              <div className="edit-sale__close-btn">
                <Popconfirm
                  title={`This will ${
                    sale.open ? 'close' : 'open'
                  } the current sale immediately. Sure?`}
                  onConfirm={handleToggleSaleOpen}
                >
                  <Button danger={sale.open} loading={isTogglingSaleOpen}>
                    <WarningOutlined />
                    {sale.open ? (
                      <span>Close current sale</span>
                    ) : (
                      <span>Open current sale</span>
                    )}
                  </Button>
                </Popconfirm>
              </div>
            </>
          ) : editType === SaleEditType.EXTRA_DAYS ? (
            <Form.Item label="Extra inventory" name="extraDays">
              <MultipleDatePicker value={values.extraDays} />
            </Form.Item>
          ) : null}
        </Modal>
      )}
    </FormLegacyRenderProp>
  );
};

export default SaleEditModal;
